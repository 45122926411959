<template>
  <div class="page-container">
    <div class="header" style="padding: 0;">
      <page-header @navBack="$router.replace('/train')" pageTitle="支付"></page-header>
    </div>
    <div class="main">
      <div class="course">
        <div class="course_img">
          <img src="http://course.sad.com/assets/123.png" alt="《机动车驾驶培训C1/C2/C3》多媒体课程学习卡" title="《机动车驾驶培训C1/C2/C3》多媒体课程学习卡">
        </div>
        <div class="course_info">
          <h3 class="title">《机动车驾驶培训C1/C2/C3》多媒体课程学习卡</h3>
          <p class="price">学习卡费用：<span>￥100</span></p>
        </div>
      </div>
    </div>
    <submit-bar
      style="border-top: 1px solid #ddd;"
      :price="10000"
      button-text="提交订单"
      @submit="$router.push('/pay')"
      button-color="#FF8506"
    />
  </div>
</template>

<script>
import PageHeader from "@/components/page-header.vue";
import { SubmitBar } from 'vant'
export default {
  name: "payCourse",
  components: {
    PageHeader,
    SubmitBar
  }
}
</script>

<style scoped>
  .main {
    padding: 19px 15px;
  }
  .main>.course {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main>.course>.course_img{
    width: 120px;
    height: 80px;
    background-color: #ddd;
    border-radius: 5px;
    text-align: center;
  }
  .main>.course>.course_info {
    width: calc(100% - 120px);
    height: 80px;
    padding-left: 15px;
    position: relative;
  }
  .main>.course>.course_info .title { font-size: 15px; margin:0; }
  .main>.course>.course_info .price {
    position: absolute;
    bottom: 0;
    left: 15px;
    margin: 0;
  }
  .main>.course>.course_info .price>span {
    font-size: 16px;
    color: #FF8506;
    font-weight: bold;
  }


  .pay_tabbar{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-top: 1px solid #ddd;
  }
  .pay_tabbar>.price {
    margin: 0;
    padding: 0 15px;
    height: 100%;
    line-height: 60px;
  }
  .pay_tabbar>.price>span{
    font-size: 22px;
    color: #FF8506;
  }
  .pay_tabbar>.pay_btn {
    width: 150px;
    height: 100%;
    line-height: 60px;
    background-color: #FF8506;
    color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    outline: none;
    border: 0;
    font-size: 16px;
  }
</style>
